import React, { useEffect, useState } from 'react';

import logo from '../../../assets/images/logo-game.svg';
import { Button, Spinner } from '@componentsShared';

import {
    InfoGame,
    ContainerGame,
    TransactionNumber,
    ContainerImage,
    OrderDetail,
    TitleDetails,
    ContentTitle,
    BoxSpinner,
    TitleNotDetail,
    ContainerGameReversed,
    TooltipCustom,
    TextProcessTransaction

} from './styled';
import siteUrl from '@utils/siteUrl';
import { ILogoModel, IProps } from './types';
import { cfConfiguracionJuegoService } from '@servicesCF/configuracionJuego';

const TransactionOrderDetail = ({ orderDetail, loading }: IProps) => {
    const logosJuegos: ILogoModel = {};

    cfConfiguracionJuegoService.getLogos().forEach((logoConfig) => {
        logosJuegos[logoConfig.abbreviation] = logoConfig;
    });

    const getLogo = (sigla: string) => {
        const siglaParse = sigla == 'SEM' ? 'CHA' : sigla;
        return logosJuegos[siglaParse]?.logo || {};
    };

    const getLogoUrl = (sigla: string): string => {
        return getLogo(sigla).file?.url || logo;
    };

    const getLogoDescription = (sigla: string): string => {
        return getLogo(sigla).description || 'Logo juego';
    };

    return (
        <OrderDetail>
            <ContentTitle>
                <TitleDetails>Detalles del pedido</TitleDetails>
            </ContentTitle>
            {loading && (
                <BoxSpinner>
                    <Spinner />
                </BoxSpinner>
            )}
            {!loading && orderDetail?.length === 0 && (
                <TitleNotDetail>No hay detalles del pedido para mostrar</TitleNotDetail>
            )}
            {!loading &&
                orderDetail?.map((order: any, index: number) => 
                    order.estadoTransaccion == 4 || !order.estadoTransaccion ? (
                        <ContainerGame key={index}>
                            <InfoGame>
                                <ContainerImage>
                                    <img
                                        src={getLogoUrl(order.sigla)}
                                        alt={getLogoDescription(order.sigla)}
                                    />
                                </ContainerImage>
                                {!order.estadoTransaccion && 
                                    <TextProcessTransaction>Procesando transacción</TextProcessTransaction>
                                }
                                <label>
                                    No. de transacción
                                    <span
                                        className="icon-info"
                                        data-tip
                                        data-iscapture
                                        data-event="click focus"
                                        data-for="transaction"
                                    />
                                </label>
                                <TooltipCustom
                                    id="transaction"
                                    type="light"
                                    place="bottom"
                                    offset={{ left: 95 }}
                                    effect="solid"
                                >
                                    <p>
                                        La clave para abrir tu apuesta es tu{' '}
                                        <b>número de identificación</b>
                                    </p>
                                </TooltipCustom>
                                <TransactionNumber>
                                    <span>{order.numTransaccion}</span>
                                </TransactionNumber>
                            </InfoGame>
                        </ContainerGame>
                    ) : (
                        <ContainerGameReversed>
                            <InfoGame>
                                <ContainerImage>
                                    <img
                                        src={getLogoUrl(order.sigla)}
                                        alt={getLogoDescription(order.sigla)}
                                    />
                                </ContainerImage>
                                <label>
                                    Su transacción no fue exitosa, esta será reintegrada como saldo
                                    en tu cuenta OKI. Te invitamos a realizarla nuevamente.
                                </label>
                                <label>No. de transacción</label>
                                <TransactionNumber>
                                    <span>{order.numTransaccion}</span>
                                </TransactionNumber>
                            </InfoGame>
                        </ContainerGameReversed>
                    )
                )}
            <Button
                text="Historial de Compras"
                onClick={() => (window.location.href = siteUrl + '/historial-de-compras')}
            ></Button>
        </OrderDetail>
    );
};

export default TransactionOrderDetail;
