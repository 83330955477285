
import React, { useState, useEffect } from 'react';

import {
    Circle,
    CircleFill, 
    MessageTitle,
    NameState,   
    TextEmail,
} from './styled';
import { IPropsTransactionHeader } from './types';
import { Button } from '@componentsShared';
import siteUrl from '@utils/siteUrl';
import { colorText } from '@utils/colorText';



const TransactionHeader = ({check, name, title, subtitles}: IPropsTransactionHeader) =>{
    
    const [parseTitle, setParseTitle] = useState('');

    useEffect(( ) => {
        if (!name || !title) {
            return;
        }
        setParseTitle(title.replace('__NAME__', name.split(' ')[0]) || '');
    }, [title, name]);

    return (
        <MessageTitle>
            <Circle>
                <CircleFill>
                    <img src={check} alt='Icono check' />
                </CircleFill>
            </Circle>
            <>
                <NameState dangerouslySetInnerHTML={{ __html: colorText(parseTitle) }} />
                {
                    subtitles.length > 0 && 
                    <TextEmail>
                        <p>{subtitles[0]?.name || null}</p>
                        {subtitles[1]?.name || null}
                    </TextEmail>
                }
                {' '}
            </>
            <Button text="Seguir jugando" onClick={() => window.location.href = siteUrl + '/juegos'}></Button>
        </MessageTitle>
    )

}

export default TransactionHeader