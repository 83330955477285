
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from "@componentsShared";
import { cfTransactionApprovedService } from "@servicesCF/transactionApproved";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { ContainerTransactionApproved } from "src/components/organisms/containerTransactionApproved";
import Viewport from 'src/shared/hooks/viewport';
import { WrapperTransactionApproved } from "./styled";

const TransactionApproved = () => {
    const isBrowser = () => typeof window !== 'undefined';
    const viewport = Viewport();
    


    const urlParams = new URLSearchParams(isBrowser() ? window.location.search : '');

    const order = urlParams.get('order');

    const pageConfirmacionCompraAprobada = cfTransactionApprovedService.getPageConfirmacionCompraAprobada();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Confirmación aprobada',
            pathname: 'confirmacion/aprobada?order='+ order
        }
    ];
   

    return (
        <Layout>
            <SEO
                slug={pageConfirmacionCompraAprobada.slug || '/confirmacion/aprobada'}
                title={pageConfirmacionCompraAprobada.metaData?.title || 'Compra aprobada'}
                description={pageConfirmacionCompraAprobada.metaData?.descripcion || 'Página de estado de transacción aprobada'}
                keyWords={pageConfirmacionCompraAprobada.metaData.keyWords || ''}
                index='noindex, nofollow'
            />
            <WrapperTransactionApproved>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
            </WrapperTransactionApproved>

            <ContainerTransactionApproved paymentURL={order || ""} pageConfirmacionCompraAprobada={pageConfirmacionCompraAprobada} />
        </Layout>
    )
}

export default TransactionApproved;