import styled from 'styled-components';
import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';

export const Container = styled.div`
    margin-top: 73px;
`;
export const TransactionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 17px 56px 17px;
    gap: 12px;
    ${breakpoint('lg')`
        flex-direction: row; 
        margin-top:65px;
        align-items: flex-start;
        gap: 15px;
    `}
`;
export const MessageTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    button {
        padding: 8px 32px;
        border-radius: 12px;
        margin-top: 28px;
        background: ${(props) => hexToRGB(props.theme.colors.principal.greenBase)};
    }
`;

export const Circle = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    width: 55px;
    height: 55px;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 18px
        ${(props) => hexToRGB(props.theme.colors.principal.greenNeon, 0.5)};
`;
export const CircleFill = styled.div`
    width: 43px;
    height: 43px;
    border: 2px solid
        ${(props) => hexToRGB(props.theme.colors.principal.greenNeon)};
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-radius: 100%;
    box-shadow: 0px 0px 5px
        ${(props) => hexToRGB(props.theme.colors.principal.greenNeon)};
    img {
        margin: 0 auto;
    }
`;

export const NameState = styled.p`
    *max-width: '';
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    text-transform: lowercase;
    ::first-letter {
        text-transform: uppercase;
    }
    span {
        color: ${(props) => props.theme.colors.principal.greenBase};
    }
`;

export const TextEmail = styled.div`
    font-size: 14px;
    line-height: 18px;
    font-family: ${(props) => props.theme.fonts.lf_light};
    margin-top: 9px;
    max-width: 217px;
    text-align: center;
    p {
        margin-bottom: 15px;
    }
    ${breakpoint('lg')`
        max-width: 409px;
    `}
`;