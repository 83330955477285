import { graphql, useStaticQuery } from 'gatsby';
import { CfConfiguracionJuego, ConfiguracionLogo } from './types';


export const cfConfiguracionJuegoService = {
    getLogos: (): ConfiguracionLogo[] => {
        const {
            allContentfulConfiguracionJuego
        } = useStaticQuery<CfConfiguracionJuego>(graphql`
            query ConfiguracionJuegoServiceGetLogos {
                allContentfulConfiguracionJuego {
                    nodes {
                        logo {
                            file {
                                url
                            }
                            description
                        }
                        abbreviation
                    }
                }
            }
        `);
        return allContentfulConfiguracionJuego.nodes;
    }
};