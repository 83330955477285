import React from 'react'

import {
    ContentDateState,
    Text,
    TitleDetails,
    TransactionDetailContainer,
    TransactionInformation,
    TotalValue,
    NameBuyer,
} from './styled';
import { converterNumber } from '@utils/decimalNumberConverter';
import { IPropsTransactionDetail } from './types';



const TransactionDetail = ({name, email, transaction}: IPropsTransactionDetail) => {
    return (
        <TransactionDetailContainer>            
            <TitleDetails >Datos de transacción</TitleDetails>
            <ContentDateState>
                <p>{transaction.createdAt}</p>
                <span>Aprobada</span>
            </ContentDateState>
            <TransactionInformation>
                <Text>
                    <label>Referencia</label>
                    <h3>{transaction.reference}</h3>
                </Text>
                <Text>
                    <label>Nombre del comprador:</label>
                    <NameBuyer>{name.toLocaleLowerCase()}</NameBuyer>
                </Text>
                <Text>
                    <label>Correo electrónico:</label>
                    <h3>{email}</h3>
                </Text>
                <Text>
                    <TotalValue>
                        <label>Total pagado</label>
                        <h2>$ {converterNumber(transaction.total)}</h2>
                    </TotalValue>
                </Text>
            </TransactionInformation>
        </TransactionDetailContainer>
    )
}

export default TransactionDetail