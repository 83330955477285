import styled from 'styled-components';
import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';

export const Container = styled.div`
    margin-top: 10px;
`;
export const TransactionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 17px 56px 17px;
    gap: 12px;
    ${breakpoint('lg')`
        flex-direction: row; 
        margin-top:65px;
        align-items: flex-start;
        gap: 15px;
    `}
`;