import styled from 'styled-components';
import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';
import ReactTooltip from 'react-tooltip';

export const ContainerGame = styled.div`
    margin-top: 19px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    width: 100%;
    min-width: 300px;
    min-height: 204px;
    border-radius: 12px;
    padding: 0px 14px;

    ${breakpoint('lg')`
        min-width: 471px;
        min-height: 191px;
        position:relative;
        margin-top: 0px;
        margin-bottom: 32px;
    `}
`;

export const ContainerGameReversed = styled.div`
    margin-top: 19px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    width: 100%;
    min-width: 300px;
    min-height: 204px;
    border-radius: 12px;
    padding: 0px 14px;
    border: 0.5px solid rgb(255, 32, 72);

    ${breakpoint('lg')`
        min-width: 471px;
        min-height: 191px;
        position:relative;
        margin-top: 0px;
        margin-bottom: 32px;
    `}
`;
export const InfoGame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12px;
    position: relative;
    img {
        width: 100%;
        max-height: 40px;
        /* min-width: 267px; */
    }
    label {
        font-family: ${(fonts) => fonts.theme.fonts.lf_bold};
        margin-top: 11px;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
    }

    .icon-info {
        font-size: 20px;
        color: ${(props) => props.theme.colors.principal.greenBase};
        margin-left: 60px;
        position: absolute;
        right: 0;
        margin-right: 10px;
    }
    .__react_component_tooltip {
        max-width: 240px;
        min-width: 240px;
        font-size: 14px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        line-height: 24px;
        ::after {
            left: 217px !important;
            margin-left: -10px !important;
        }
    }
    ${breakpoint('lg')`
        margin-top: 17px;
        .icon-info{
            font-size:18px;
        }
        label{
            margin-top: 15px;
            font-size: 14px;
            margin-bottom: 6px;
        }
    
        `}
`;

export const TransactionNumber = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 12px;
    margin-bottom: 15px;
    span {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        font-size: 13px;
        line-height: 22px;
        min-height: 27px;
        min-width: 200px;
        font-family: ${(fonts) => fonts.theme.fonts.lf_light};
        border-radius: 12px;
        padding: 2.5px 34.93px;
        margin: 0 auto;
    }
    ${breakpoint('lg')`
        border-bottom: none;
        span{
            padding: 9px 70.43px;
            line-height: 14px; 
            min-width: 271px;
            min-height: 32px;
        }
    `}
`;
export const ContentTitle = styled.div`
    width: 100%;
    min-width: 295px;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 16px;
    ${breakpoint('lg')`
    padding-bottom:0px;
    border-bottom: none;
    `}
`;
export const ContainerImage = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 267px;
    min-height: 44px;
    background: ${(props) => props.theme.colors.text.white};
    border-radius: 12px;
    ${breakpoint('lg')`
        min-height: 56px;

    `}
`;

export const OrderDetail = styled.div`
    width: 100%;
    min-height: 314px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 17px 13px 17px;
    div:nth-child(2) {
        margin-top: 16px;
    }
    ${breakpoint('lg')`
        min-height:314px;
        min-width:504px;
        max-width:504px;
    `}
    a {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.principal.greenNeon};
        margin: 0 auto;
        text-decoration: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }
`;
export const BoxSpinner = styled.div`
    margin: auto !important;
`;

export const TitleDetails = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 12px;
    margin-top: 17px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    padding: 12px 0px;
    width: 100%;
    min-width: 295px;
    max-height: 40px;
`;
export const TitleNotDetail = styled.span`
     font-family: ${(props) => props.theme.fonts.lf_medium};
     margin-top: 25%;
     margin-bottom: 25%
 `;
 export const TooltipCustom = styled(ReactTooltip)`
    border-radius: 16px !important;
    width: 50%;
    box-shadow: 0 0 9px #333;
    max-width: 230px;
    pointer-events: auto !important;

    p {
        color: #3E3A47;
        font-family: ${(props) => props.theme.fonts.lf_medium};
        font-size: 12px;
        line-height: 14px;
        b {
            color: #009f5c;
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }
`;

export const TextProcessTransaction = styled.p`
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 14px;
    margin-top: 10px;
`;