import { graphql, useStaticQuery } from 'gatsby';
import { CfPageConfirmacionCompraAprobada, PageConfirmacionCompraAprobada } from './types';


export const cfTransactionApprovedService = {
    getPageConfirmacionCompraAprobada:  () : PageConfirmacionCompraAprobada  => {
        const {
            allContentfulPaginaInternaFooter
        } = useStaticQuery<CfPageConfirmacionCompraAprobada>(graphql`
            query ConfirmacionCompraAprobada {
                allContentfulPaginaInternaFooter(
                    filter: {page: {eq: "ConfirmacionCompraAprobada"}}
                ) {
                    nodes {
                        title      
                        subtitulos {
                            name
                        }
                        pageIcon {
                            description
                            file {
                            url
                            }
                            title
                        }
                        metaData {
                            title
                            descripcion
                            keyWords
                        }
                        slug
                    }
                }
            }
        `);
        return allContentfulPaginaInternaFooter.nodes[0] || {};
    }
};