import styled from 'styled-components';
import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';

export const TransactionDetailContainer = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    min-width: 326px;
    border: 0.5px solid
        ${(props) => props.theme.colors.principal.greenBase};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 0px 17px;
    width: 100%;
    ${breakpoint('lg')`
        min-height:314px;
        min-width:504px;
        max-width:504px;
        max-height: 314px;
     `}
`;
export const OrderDetail = styled.div`
    width: 100%;
    min-height: 314px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 17px 13px 17px;
    div:nth-child(2) {
        margin-top: 16px;
    }
    ${breakpoint('lg')`
        min-height:314px;
        min-width:504px;
        max-width:504px;
    `}
    a {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.principal.greenNeon};
        margin: 0 auto;
        text-decoration: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }
`;
export const BoxSpinner = styled.div`
    margin: auto !important;
`;

export const TitleDetails = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 12px;
    margin-top: 17px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    padding: 12px 0px;
    width: 100%;
    min-width: 295px;
    max-height: 40px;
`;

export const ContentDateState = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11px;
    margin-top: 15px;
    width: 100%;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 15px;
    span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        padding: 4px 39.6px;
        border-radius: 16px;
        background: ${(props) => props.theme.colors.principal.greenBase};
        color: ${(props) => props.theme.colors.backgrounds.gray500};
    }
    p {
        font-size: 12px;
        line-height: 14px;
        color: ${(props) => props.theme.colors.text.gray};
        font-family: ${(props) => props.theme.fonts.lf_light};
        ::first-letter {
            text-transform: uppercase;
        }
    }
    ${breakpoint('lg')`
        flex-direction: row;
        justify-content: space-between;
        span {
            font-size: 12px;
            line-height: 20px;
            font-family: ${(props) => props.theme.fonts.lf_regular};            
            padding: 6px 10px;
            border-radius: 16px;
        }
    `}
`;
export const TransactionInformation = styled.div`
    margin-top: 13px;
    width: 100%;
    div:nth-child(odd) {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        width: 100%;
        height: 75px;
    }

    ${breakpoint('lg')`
        div:nth-child(odd) {
            height:32px;
        }
        div:nth-last-child(2){
            margin-bottom:19px;
        }  
    `}
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 24px;
    border-radius: 12px;
    margin-bottom: 20px;
    font-size: 14px;

    font-family: ${(fonts) => fonts.theme.fonts.lf_semiBold};
    h3 {
        font-family: ${(fonts) => fonts.theme.fonts.lf_light};
    }

    ${breakpoint('lg')`
        flex-direction: row;
        margin-bottom: 9px;
        label{
            text-align: right;
            width: 50%;
        }
        h3{
            width: 50%;
            text-align:left; 
            margin-left: 77px;
        }
        
    `}
`;
export const TotalValue = styled.section`
    display: flex;
    flex-direction: column;
    h2 {
        font-family: ${(fonts) => fonts.theme.fonts.lf_bold};
        color: ${(props) => props.theme.colors.principal.greenBase};
        font-size: 16px;
        margin-top: 3px;
    }
    ${breakpoint('lg')`
        flex-direction: row;
        width:100%;
            justify-content:end; 
            align-items: end;
            gap: 10px;
            h2{
                font-size: 20px;
                margin-right:29px;
                margin-top: 0px;
            }
            border-top: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
            padding-top:10px;
    `}
`;
export const NameBuyer = styled.h3`
    text-transform: capitalize;
`;