import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';


import {
    Container,
    TransactionContainer,
} from './styled';
import check from '../../../assets/images/check.svg';
import TransactionHeader from 'src/components/molecules/transactionHeader';
import { AppContextUser } from 'src/shared/context/user';
import TransactionDetail from 'src/components/molecules/transactionDetail';
import { IOrderInfo, ITransaction } from 'src/components/organisms/containerTransaction/types';

import TransactionOrderDetail from 'src/components/molecules/transactionOrderDetail';
import { carServices } from '@services/car';
import { getData } from '@utils/transferData';
import { PageConfirmacionCompraAprobada } from '@servicesCF/transactionApproved/types';
import { IOrderDetail } from './types';
import { getDataModalOder, getOrder } from '@utils/history';
import { pushEskEvent, pushFbqEvent, pushGtagEvent } from '@utils/metricManagementUtil';

type IProps = {
    paymentURL: string
    pageConfirmacionCompraAprobada: PageConfirmacionCompraAprobada;
}

const transactionEmpty: ITransaction = {
    createdAt: ' ',
    reference: ' ',
    responseJson: ' ',
    state: false,
    total: 0,
    __typename: ' '
};


export const ContainerTransactionApproved = ({ paymentURL, pageConfirmacionCompraAprobada }: IProps) => {
    moment.locale('es');
    const { dataUser, setDataUser } = useContext(AppContextUser);
    const [dataTransaction, setDataTransaction] = useState<ITransaction>(transactionEmpty);
    const [dataOrderDetail, setDataOrderDetail] = useState<IOrderDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const isBrowser = () => typeof window !== 'undefined';

    const homePage = () => {
        if(isBrowser()) {
            window.location.href = '/';
        }
    }

    const rejectedPage = () => {
        if(isBrowser()) {
            window.location.href = `/confirmacion/rechazada?order=${paymentURL}`;
        }
    }

    const getDataOrderDetail = async (
        token: string,
        tipoDocumento: string,
        ideUsuario: string,
        reference: string,
        transaction: string
    ) => {
        await carServices
            .getOrderDetail(token, tipoDocumento, ideUsuario, reference, transaction)
            .then((data) => {
                const datos: IOrderDetail[]  = data.data.getOrderDetail;
                if (datos.length > 0) {
                    setDataOrderDetail(datos);
                    const transaccionesNoProcesadas = datos.filter((dato: IOrderDetail) => (!dato.estadoTransaccion));
                    if(transaccionesNoProcesadas.length > 0) {
                        setTimeout(() => {
                            getDataOrderDetail(
                                token, 
                                tipoDocumento, 
                                ideUsuario, 
                                reference, 
                                transaction
                            );
                        }, 30000);
                    }
                    setLoading(false);
                } else {
                    setTimeout(() => {
                        getDataOrderDetail(
                            token, 
                            tipoDocumento, 
                            ideUsuario, 
                            reference, 
                            transaction
                        );
                    }, 5000);
                }
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }

    const getTransactionState = async (
        token: string,
        tipoDocumento: string,
        ideUsuario: string
    ) => {
        await carServices
            .transactionState(token, tipoDocumento, ideUsuario, paymentURL)
            .then((data) => {
                const dataInitial = data.data.getPayment;
                pushGtagEvent('purchase', {
                    transaction_id: paymentURL || '',
                    value: dataInitial.total || 0,
                    tax: '',
                    currency: 'COP',
                    coupon: ''
                });
                pushFbqEvent('Purchase', {
                    currency: 'COP',
                    value: dataInitial.total || 0,
                });
                pushEskEvent();
                if(dataInitial == null) {
                    homePage();
                }
                const responseJson = JSON.parse(dataInitial.responseJson);
                const dataTransaction = getDataModalOder(responseJson, paymentURL);
                const date = moment(dataTransaction.date).format('dddd,  D  MMMM  YYYY, h:mm A');
                const transactionId = getOrder(responseJson)
                const state = dataInitial.state === 'APPROVED' ? true : false;
                if(!state) {
                    rejectedPage();
                }
                setDataTransaction({
                    ...dataInitial,
                    createdAt: date
                });
                getDataOrderDetail(
                    token, 
                    tipoDocumento, 
                    ideUsuario, 
                    dataInitial.reference, 
                    transactionId
                )
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        let dataLocalStorage = getData('userData');
        if(!dataLocalStorage.user || dataLocalStorage.user === '') {
            homePage();
        }
        if (dataLocalStorage) {
            let token = CryptoJS.AES.decrypt(
                dataLocalStorage.token,
                process.env.ENCRYPTION_KEY || ''
            );
            getTransactionState(
                token.toString(CryptoJS.enc.Utf8),
                dataLocalStorage.type,
                dataLocalStorage.user
            );
        }
    }, []);

    return (
        <Container>
            <TransactionHeader
                check={pageConfirmacionCompraAprobada.pageIcon?.file?.url || check}
                name={dataUser.nombreCompleto || ''}
                title={pageConfirmacionCompraAprobada.title || ''}
                subtitles={pageConfirmacionCompraAprobada.subtitulos || []}
            />
            <TransactionContainer>
                <TransactionDetail
                    transaction={dataTransaction}
                    name={dataUser.nombreCompleto || ''}
                    email={dataUser.email}
                />
                <TransactionOrderDetail 
                    orderDetail={dataOrderDetail} 
                    loading={loading}
                    />
            </TransactionContainer>
        </Container>
    );
};